import styles from '@sicredi/styles/_shared.scss';
import React, { Fragment } from 'react';

export type NullableString = string | null | undefined;

interface BoldTextProps {
  children: string;
}

interface Props {
  text: NullableString;
  children: string;
}

export interface CompoundedHighlighter extends React.FC<Props> {
  BoldText: React.FC<BoldTextProps>;
}

const Highlighter: CompoundedHighlighter = ({ text, children }) => {
  if (!text || !text.trim()) {
    return <Fragment>{children}</Fragment>;
  }

  const escapeRegex = /(?=[() | [ \] \/ ])/g;
  const highlight = text.replace(escapeRegex, '\\');
  const highlightRegex = new RegExp(`(${highlight})`, 'gi');
  const parts = children.split(highlightRegex);
  return (
    <Fragment>
      {parts.map((part: string, index) =>
        part.replace(escapeRegex, '\\').toLowerCase() ===
        highlight.toLowerCase() ? (
          <span className={styles['highlighted-text']} key={index}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </Fragment>
  );
};

const BoldText: React.FC<BoldTextProps> = ({ children }) => (
  <span className={styles['highlighted-text']}>{children}</span>
);

Highlighter.BoldText = BoldText;

export default Highlighter;
