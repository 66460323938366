/* istanbul ignore file */
import React from 'react';
import { pascalCase } from 'change-case';

export const AssetsNames = [
  'action-error',
  'action-loading-clock',
  'action-not-found',
  'action-success',
  'document-send-success',
  'sicredi-icon',
  'sicredi-icon-negative',
  'sicredi-logo',
  'sicredi-logo-negative',
  'padlock-closed',
  'product-signed',
  'document-sent',
  'gratitude-feedback',
  'verification-email',
  'request-under-review',
  'reminder-and-pending',
  'wait',
  'generic-system-error',
  'support',
  'house',
  'search',
  'council',
  'relationship',
  'unity',
  'padlock',
  'bond-of-association',
  'account-manager',
  'sent-email',
  'phone-selfie',
] as const;

export interface AssetProps extends React.SVGProps<SVGSVGElement> {
  name: typeof AssetsNames[number];
}

/**
 * @function Asset
 * @description Asset Component that can be used to render assets from Sicredi Images Database
 */
const Asset: React.FC<AssetProps> = ({ name, ...rest }) => {
  const importedAssetRef = React.useRef<
    React.FC<React.SVGProps<SVGSVGElement>>
  >();

  const [loading, setLoading] = React.useState(false);

  const importAsset = async (): Promise<void> => {
    try {
      setLoading(true);
      const assetName = pascalCase(name).replace('_', '');
      // @ts-ignore
      const { [assetName]: asset } = await import(`@sicredi/react-assets/dist/images/index.esm.js`);
      importedAssetRef.current = asset;
    } catch (err) {
      throw new Error(`O asset "${name}" que você está tentando importar não foi encontrado na biblioteca de assets com este nome`);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    importAsset();
  }, [name]);

  if (!loading && importedAssetRef.current) {
    const { current: ImportedAsset } = importedAssetRef;
    return <ImportedAsset {...rest} />;
  }

  return null;
};

export default Asset;
