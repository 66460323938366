import clsx from 'clsx';
import Icon, { IconNames } from '../icon/Icon';
import React, { useMemo } from 'react';
import styles from '@sicredi/styles/_icon-button.scss';
import Tooltip, { Props as TooltipProps } from '../tooltip/Tooltip';

export type IconButtonProps = {
  name: typeof IconNames[number];
  label?: string;
  size?: 'x-small' | 'small' | 'medium' | 'large';
  appearance?: 'default' | 'outlined';
  active?: boolean;
  containerClassName?: string;
  buttonClassName?: string;
  iconClassName?: string;
  tip?: TooltipProps['tip'];
  notificationBadge?: boolean;
  notificationBadgeCounter?: number | string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export type Ref = HTMLButtonElement;

const IconButton = React.forwardRef<Ref, IconButtonProps>(
  (
    {
      name,
      label = 'Ação',
      size = 'x-small',
      appearance = 'default',
      containerClassName,
      buttonClassName,
      iconClassName,
      active,
      tip,
      notificationBadge = false,
      notificationBadgeCounter = '',
      ...props
    },
    ref
  ) => {
    const buttonRef = React.useRef<HTMLButtonElement>(null)

    React.useImperativeHandle(ref, () => buttonRef.current as HTMLButtonElement);

    const infoBadge = useMemo(() => {
      if (typeof notificationBadgeCounter === 'number') {
        if (notificationBadgeCounter > 99) return '+99';
        if (notificationBadgeCounter <= 0) return null;
      }
      return notificationBadgeCounter;
    }, [notificationBadgeCounter]);

    const iconViewBox = useMemo(
      () => (['x-small', 'small'].includes(size) ? 16 : 24),
      [size]
    );

    function handleButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
      buttonRef?.current?.blur?.()
      event.preventDefault();
      props?.onClick?.(event)
    }

    return (
      <WithTooltip tip={tip} placement="bottom">
        <div
          className={clsx(
            styles['button-container'],
            styles[`-${size}`],
            containerClassName
          )}
        >
          <button
            ref={buttonRef}
            aria-label={label}
            className={clsx(
              styles['sicredi-icon-button'],
              {
                [styles['-active']]: !!active,
                [styles['-disabled']]: !!props.disabled,
              },
              styles[`-${appearance}`],
              styles[`-${size}`],
              buttonClassName
            )}
            {...props}
            onClick={handleButtonClick}
          >
            <Icon
              name={name}
              className={iconClassName}
              width={iconViewBox}
              height={iconViewBox}
              viewBox="0 0 24 24"
            />
          </button>
          <div
            className={clsx(styles['focus-highlight'], styles[`-${size}`])}
          />
          {notificationBadge && infoBadge && (
            <div
              className={clsx(
                styles['-notification-container'],
                styles[`-${size}`]
              )}
              data-testid={`${props?.['data-testid']}-badge-container`}
            >
              <div
                className={clsx(
                  styles['-notification-badge'],
                  styles[`-${size}`]
                )}
                data-testid={`${props?.['data-testid']}-badge-value`}
              >
                {!['x-small', 'small'].includes(size) && infoBadge}
              </div>
            </div>
          )}
        </div>
      </WithTooltip>
    );
  }
);

const WithTooltip = ({
  tip,
  placement = 'bottom',
  children,
  ...rest
}: TooltipProps) => {
  if (tip) {
    return (
      <Tooltip placement={placement} tip={tip} {...rest}>
        {children}
      </Tooltip>
    );
  }
  return <>{children}</>;
};

export default IconButton;