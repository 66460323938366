import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'debounce';

import { Tooltip } from '../../tooltip';
import { Props as TooltipProps } from '../../tooltip/Tooltip';
import styles from './Ellipsis.scss';

const DEBOUNCE_TIME = 250;
export interface Props {
  children?: React.ReactNode;
  lineClamp?: number;
  placement?: TooltipProps['placement'];
}

const Ellipsis: React.FC<Props> = ({
  children,
  lineClamp,
  placement = 'top',
}) => {
  const el = useRef<HTMLDivElement>(null);

  const [hasEllipsis, setHasEllipsis] = useState(false);

  useEffect(() => {
    const element = el.current;
    // Inicializa um observador em cada child do children repassado ao componente
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        // Verifica se o elemento está visível
        if (entry.isIntersecting && element) {
          // Se estiver visível, cria o debounce com o intervalo especificado (DEBOUNCE_TIME)
          const handleHasEllipsis = debounce(
            () => setHasEllipsis(element.offsetHeight < element.scrollHeight),
            DEBOUNCE_TIME
          );
          // Chama o callback que verifica se o elemento filho ultrapassou a largura do container pai
          handleHasEllipsis();
        }
      });
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [el.current]);

  const enhancedChildren = useMemo(() => {
    return (
      <Fragment>
        {React.Children.map(children, child => (
          <div
            ref={el}
            className={styles['sicredi-ellipsis']}
            style={{
              WebkitLineClamp: lineClamp,
              lineBreak: lineClamp === 1 ? 'anywhere' : 'normal',
            }}
          >
            {child}
          </div>
        ))}
      </Fragment>
    );
  }, [children]);

  // Portanto, se o elemento está visível e ultrapassou a largura de seu container é envolvido com o Tooltip
  return hasEllipsis ? (
    <Tooltip placement={placement} className={styles['tooltip']} tip={children}>
      {enhancedChildren}
    </Tooltip>
  ) : (
    enhancedChildren
  );
};

Ellipsis.defaultProps = {
  lineClamp: 1,
};

export default Ellipsis;
