import * as React from 'react';
import cn from 'clsx';
import TextareaAutosize from 'react-textarea-autosize';
import styles from '@sicredi/styles/_textarea.scss';

import FieldSubscript from '../shared/field-subscript/FieldSubscript';

export interface Props
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  children?: React.ReactNode;
  label: React.ReactNode;
  hintMessage?: React.ReactNode;
  errorMessage?: React.ReactNode;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, Props>((
  {
    name,
    value,
    label,
    onChange,
    required,
    disabled,
    readOnly,
    className,
    maxLength,
    hintMessage,
    errorMessage,
    ...props
  },
  ref
) => {
  const [internalValue, setInternalValue] = React.useState('');

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>): void {
    setInternalValue(e.target.value);

    onChange && onChange(e);
  }

  return (
    <div
      className={cn(styles['sicredi-textarea'], className, {
        [styles['-invalid']]: !!errorMessage,
        [styles['-disabled']]: disabled,
        [styles['-readonly']]: readOnly,
      })}
    >
      <TextareaAutosize
        id={`${name}-textarea`}
        inputRef={ref || undefined}
        name={name}
        value={String(value !== undefined ? value : internalValue)}
        onChange={handleChange}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        className={styles['textarea']}
        aria-invalid={errorMessage ? 'true' : 'false'}
        data-textarea=""
        aria-readonly={readOnly ? 'true' : 'false'}
        aria-disabled={disabled ? 'true' : 'false'}
        aria-required={required ? 'true' : 'false'}
        aria-labelledby={`${name}-textarea-label`}
        aria-errormessage={`${name}-textarea-error`}
        maxRows={5}
        {...props}
      />
      <label
        id={`${name}-textarea-label`}
        htmlFor={`${name}-textarea`}
        className={styles['label']}
        data-label=""
      >
        {label} {!required && !disabled && '(Opcional)'}
      </label>
      {!disabled && (
        <FieldSubscript>
          <FieldSubscript.Group>
            <FieldSubscript.Message
              id={`${name}-textarea-error`}
              show={!!errorMessage}
              invalid={true}
              aria-live="polite"
              role="alert"
              data-testid={
                props['data-testid']
                  ? `${props['data-testid']}-error`
                  : undefined
              }
              data-message=""
            >
              {errorMessage}
            </FieldSubscript.Message>
            <FieldSubscript.Message
              id={`${name}-input-hint`}
              show={!!hintMessage && !errorMessage}
              data-testid={
                props['data-testid']
                  ? `${props['data-testid']}-hint`
                  : undefined
              }
              data-message=""
            >
              {hintMessage}
            </FieldSubscript.Message>
          </FieldSubscript.Group>

          {maxLength && (
            <FieldSubscript.Progress
              max={Number(maxLength)}
              value={String(value !== undefined ? value : internalValue).length}
            />
          )}
        </FieldSubscript>
      )}
    </div>
  );
});

export default Textarea;
