import styles from '@sicredi/styles/_toast.scss';
import variables from '@sicredi/styles/_variables.scss';
import cn from 'clsx';
import Mitt, { Emitter } from 'mitt';
import * as React from 'react';

import { timer } from '../utils';

import Toast, { ToastActionButtonConfig, Props as ToastProps } from './Toast';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  timeout?: number;
}

const TOAST_SHOW = 'toast.show';
const TOAST_DISMISS = 'toast.dismiss';

const emitter: Emitter = Mitt();

let i = 0;

function incrementToastId() {
  i = i + 1;
}

// tslint:disable-next-line:no-empty
function noop() {}

const Container: React.FC<Props> = ({ timeout, className, ...props }) => {
  const [timers, setTimers] = React.useState({});
  const [toasts, setToasts] = React.useState({});

  function playTimer(toastId: string, force?: boolean) {
    (!toasts[toastId].focused || force) &&
      timers[toastId] &&
      timers[toastId].play();
  }

  function pauseTimer(toastId: string) {
    timers[toastId] && timers[toastId].pause();
  }

  function addToast(toast: ToastProps): string {
    const toastId = String(i);

    incrementToastId();

    setToasts(toasts => ({ ...toasts, [toastId]: toast }));

    return toastId;
  }

  function handleFocus(toastId: string) {
    setToasts(toasts => ({
      ...toasts,
      [toastId]: { ...toasts[toastId], focused: true },
    }));

    pauseTimer(toastId);
  }

  function handleBlur(toastId: string) {
    setToasts(toasts => ({
      ...toasts,
      [toastId]: { ...toasts[toastId], focused: false },
    }));

    playTimer(toastId, true);
  }

  function addTimer(toastId: string) {
    setTimers(timers => ({
      ...timers,
      [toastId]: timer(() => handleDismiss(toastId), timeout),
    }));
  }

  function showToast(toastId?: string) {
    setToasts(toasts =>
      Object.keys(toasts).reduce(
        (acc, key) => ({
          ...acc,
          [key]: { show: toastId === key, ...toasts[key] },
        }),
        {}
      )
    );
  }

  function hideToast(toastId?: string) {
    setToasts(toasts =>
      Object.keys(toasts).reduce(
        (acc, key) => ({
          ...acc,
          [key]: { ...toasts[key], show: toastId && !(toastId === key) },
        }),
        {}
      )
    );
  }

  function removeTimer(toastId?: string) {
    setTimers(timers =>
      Object.keys(timers).reduce((acc, key) => {
        if (toastId && toastId !== key) {
          return {
            ...acc,
            [key]: timers[key],
          };
        }

        timers[key].clear();

        return acc;
      }, {})
    );
  }

  function removeToast(toastId?: string) {
    setToasts(toasts =>
      Object.keys(toasts).reduce((acc, key) => {
        if (toastId && toastId !== key) {
          return {
            ...acc,
            [key]: toasts[key],
          };
        }

        toasts[key].onClose();

        return acc;
      }, {})
    );
  }

  function handleShow(toast: ToastProps) {
    const toastId = addToast(toast);

    setTimeout(() => {
      showToast(toastId);
      addTimer(toastId);
    });
  }

  function handleDismiss(toastId?: string) {
    removeTimer(toastId);
    hideToast(toastId);

    setTimeout(() => {
      removeToast(toastId);
    }, parseFloat(variables.animationTiming) * 1000);
  }

  React.useEffect(() => {
    emitter.on(TOAST_SHOW, handleShow);
    emitter.on(TOAST_DISMISS, handleDismiss);

    return () => {
      emitter.off(TOAST_SHOW, handleShow);
      emitter.off(TOAST_DISMISS, handleDismiss);
    };
  }, []);

  return (
    <div
      role="group"
      className={cn(styles['sicredi-toast-wrapper'], className)}
      {...props}
    >
      <div className={styles['sicredi-toast-inner']}>
        {Object.entries(toasts).map(([toastId, toast]: any) => (
          <Toast
            key={toastId}
            show={toast.show}
            onBlur={() => handleBlur(toastId)}
            onFocus={() => handleFocus(toastId)}
            onClose={() => handleDismiss(toastId)}
            actionButton={toast.actionButton}
            appearance={toast.appearance}
            data-testid={
              props['data-testid'] ? `${props['data-testid']}-toast` : undefined
            }
            onMouseEnter={() => pauseTimer(toastId)}
            onMouseLeave={() => playTimer(toastId)}
          >
            {toast.children}
          </Toast>
        ))}
      </div>
    </div>
  );
};

Container.defaultProps = {
  timeout: 7500,
  'aria-label': 'Notificações',
};

export default Container;

export function toast(
  children: React.ReactNode,
  onClose = noop,
  appearance: ToastProps['appearance'],
  actionButton?: ToastActionButtonConfig
): string {
  const toastId = String(i);
  emitter.emit(TOAST_SHOW, {
    onClose,
    children,
    appearance,
    actionButton
  });

  return toastId;
}

toast.error = function(children: React.ReactNode, onClose = noop, actionButton?: ToastActionButtonConfig): string {
  return toast(children, onClose, 'error', actionButton);
};

toast.warn = function(
  children: React.ReactNode,
  onClose = noop,
  actionButton?: ToastActionButtonConfig
): string {
  return toast(children, onClose, 'warning', actionButton);
};

toast.success = function(children: React.ReactNode, onClose = noop, actionButton?: ToastActionButtonConfig): string {
  return toast(children, onClose, 'success', actionButton);
};

toast.dismiss = function(toast?: string) {
  emitter.emit(TOAST_DISMISS, toast);
};
