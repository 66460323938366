import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import styles from '@sicredi/styles/_file.scss';
import cn from 'clsx';
import { Title } from '../title';
import { Button } from '../button';
import { Spacing } from '../spacing';
import FieldSubscript from '../shared/field-subscript/FieldSubscript';
import Item, { Props as ItemProps } from './Item';
import { Icon } from '../icon';

export interface Props
  extends Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    Exclude<keyof React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>
  > {
  name: string;
  label?: React.ReactNode;
  onChange?(acceptedFiles: File[]): void;
  hintMessage?: React.ReactNode;
  errorMessage?: React.ReactNode;
  children?: React.ReactElement<ItemProps>[];
  resetFilesOnChange?: boolean
}

export interface CompoundedFile extends React.FC<Props> {
  Item: React.FC<ItemProps>;
}

const File: CompoundedFile = (
  {
    name,
    label,
    children,
    onChange,
    required,
    multiple,
    className,
    hintMessage,
    errorMessage,
    resetFilesOnChange,
    ...props
  },
  ref
) => {
  const [internalItems, setInternalItems] = React.useState<ItemProps[]>([]);
  const isEmpty = (children || internalItems).length === 0;
  const isError = !!errorMessage;
  const isSingleFilled = !multiple && !isEmpty;
  const isFilesNotRemovable =
    !multiple &&
    children &&
    children.length !== 0 &&
    children.every(component => !component.props.onRemove);

  const handleChange = React.useCallback(
    (acceptedFiles: File[]) => {
      if (isSingleFilled || isFilesNotRemovable) return;

      const items = [
        ...(resetFilesOnChange ? [] : internalItems),
        ...acceptedFiles.map(acceptedFile => ({ file: acceptedFile })),
      ];
      setInternalItems(() => items);
      onChange && onChange(items.map(({ file }) => file as File));
    },
    [internalItems, isFilesNotRemovable, isSingleFilled, onChange]
  );

  const handleDelete = React.useCallback(
    (index) => {
      const items = internalItems.filter((_, i) => i !== index);
      setInternalItems(() => items);
      onChange && onChange(items.map(({ file }) => file as File));
    },
    [internalItems, onChange]
  );

  const { isDragActive, open, getRootProps, getInputProps } = useDropzone({
    multiple,
    onDrop: handleChange,
  });

  const FileItems = React.useMemo(
    () =>
      children && !!children.length
        ? children
        : internalItems.map((internalItem, index) => (
          <Item
            key={index}
            onRemove={() => handleDelete(index)}
            isError={isError}
            {...internalItem}
            {...{
              as: 'a',
              download: internalItem.file.name,
              href: window.URL.createObjectURL(internalItem.file as File),
              'data-testid': props['data-testid'],
            }}
          />
        )
        ),
    [internalItems, children, handleDelete]
  );

  const getIcon = () => {
    if (isEmpty) {
      return <Icon name="upload" size={104} appearance="disabled" />
    }

    if (isError) {
      return <Icon name="document-error" size={104} appearance="danger" />
    }

    return <Icon name="document-checked" size={104} appearance="disabled" />
  }

  return (
    <div
      {...getRootProps({
        tabIndex: undefined,
        className: cn(styles['sicredi-file'], styles['-new'], className, {
          [styles['-invalid']]: !!errorMessage,
          [styles['-valid']]: !errorMessage && !isEmpty,
          [styles['-dragging']]: isDragActive,
          [styles['-success']]: isFilesNotRemovable,
        }),
      })}
      onClick={undefined}
    >
      <input
        ref={ref}
        data-input=""
        {...getInputProps({
          name,
          required,
          id: `${name}-file`,
          type: 'file',
          style: {},
          tabIndex: 0,
          className: styles['input'],
          'aria-invalid': errorMessage ? 'true' : 'false',
          'aria-required': required ? 'true' : 'false',
          'aria-labelledby': `${name}-file-label`,
          'aria-describedby': `${name}-file-instruction`,
          'aria-errormessage': `${name}-file-error`,
          ...props,
        })}
      />
      <div data-dropzone="" className={styles['dropzone']}>
        <div data-icon="" className={styles['icon']}>
          {getIcon()}
        </div>

        <div data-content="" className={styles['content']}>
          {label && (
            <React.Fragment>
              <Title as="h4" data-label="" id={`${name}-file-label`}>
                {label} {!required && '(Opcional)'}
              </Title>
              <Spacing appearance="small" />
            </React.Fragment>
          )}
          {isEmpty ? (
            <p
              id={`${name}-file-instruction`}
              className={styles['instruction']}
              data-instruction=""
            >
              <span className={styles['wrapper']}>
                Arraste e solte o arquivo nesta área ou{' '}
                <Button className={styles['action']} onClick={open}>
                  procure no dispositivo.
                </Button>
              </span>
            </p>
          ) : (
            <React.Fragment>
              <ul
                data-files=""
                className={styles['files']}
                data-testid={
                  props['data-testid']
                    ? `${props['data-testid']}-files`
                    : undefined
                }
              >
                {FileItems}
              </ul>
              {multiple && (
                <Button
                  onClick={open}
                  className={styles['action']}
                  data-add-file=""
                  data-testid={
                    props['data-testid']
                      ? `${props['data-testid']}-add-button`
                      : undefined
                  }
                >
                  Adicionar mais arquivos
                </Button>
              )}
            </React.Fragment>
          )}
        </div>

        <div
          data-dropmessage=""
          className={styles['dropmessage']}
          aria-hidden={isDragActive ? 'false' : 'true'}
          data-testid={
            props['data-testid']
              ? `${props['data-testid']}-dropmessage`
              : undefined
          }
        >
          {isFilesNotRemovable
            ? 'Não é possível alterar o arquivo'
            : isSingleFilled
              ? 'Delete para substituir'
              : 'Solte para carregar'}
        </div>
      </div>
      <FieldSubscript>
        <FieldSubscript.Group>
          <FieldSubscript.Message
            id={`${name}-file-error`}
            show={!!errorMessage}
            invalid={true}
            aria-live="polite"
            role="alert"
            data-testid={
              props['data-testid'] ? `${props['data-testid']}-error` : undefined
            }
            data-message=""
          >
            {errorMessage}
          </FieldSubscript.Message>
          <FieldSubscript.Message
            id={`${name}-input-hint`}
            show={!!hintMessage && !errorMessage}
            data-testid={
              props['data-testid'] ? `${props['data-testid']}-hint` : undefined
            }
            data-message=""
          >
            {hintMessage}
          </FieldSubscript.Message>
        </FieldSubscript.Group>
      </FieldSubscript>
    </div>
  );
};

File.defaultProps = {
  multiple: false,
};

File.Item = Item;

export default File;
