import * as React from 'react';

import { Icon } from '..';
import cn from 'clsx';
import styles from '@sicredi/styles/_badge.scss';

const ICON = {
  danger: (isLabelled: boolean) =>
    isLabelled ? (
      <Icon name="feedback-error" size={16} />
    ) : (
      <Icon name="close" size={16} />
    ),
  success: (isLabelled: boolean) =>
    isLabelled ? (
      <Icon name="feedback-success" size={16} />
    ) : (
      <Icon name="check" size={16} />
    ),
  warning: (isLabelled: boolean) =>
    isLabelled ? <Icon name="alert" /> : <Icon name="exclamation" size={16} />,
  info: () => <Icon name="feedback-info" size={16} />,
  progress: () => <Icon name="clock" size={16} />,
};

export interface Props {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  appearance?: 'info' | 'danger' | 'success' | 'warning' | 'progress';
  'data-testid'?: string;
}

const Badge: React.FC<Props> = ({ icon, children, appearance, ...props }) => {
  const isLabelled = children !== undefined;

  return (
    <div
      aria-label={props['aria-label']}
      className={cn(styles['sicredi-badge'], styles[`-${appearance}`], {
        [styles['-labelled']]: isLabelled,
      })}
      data-testid={props['data-testid']}
      {...props}
    >
      <span
        aria-hidden="true"
        className={cn(styles['icon'])}
        data-testid={props['data-testid'] && `${props['data-testid']}-icon`}
      >
        {icon ? icon : ICON[appearance!](isLabelled)}
      </span>
      {children}
    </div>
  );
};

Badge.defaultProps = {
  appearance: 'info',
};

export default Badge;
