import '@sicredi/styles/_datepicker.scss';
import cn from 'clsx';
import formatDate from 'date-fns/format';
import isValidDate from 'date-fns/isValid';
import parseDate from 'date-fns/parse';
import parseISODate from 'date-fns/parseISO';
import pickmeup, {
  Instance as PickmeupInstance,
  InstanceOptions as PickmeupInstanceOptions,
} from 'pickmeup';
import * as React from 'react';
import { Icon } from '../icon';
import Input, { Props as InputProps } from '../input/Input';
import MaskedInput from '../masked-input/MaskedInput';
import { isMobile } from '../utils';
import { NEXTICON, PREVICON } from './constants';
import { Masks } from '@sicredi/mask';

export interface Props
  extends Pick<
    InputProps,
    Exclude<keyof InputProps, 'onChange' | 'min' | 'max'>
  > {
  mode?: 'single' | 'multiple' | 'range';
  onInit?: () => void;
  onChange?: (value: string) => void;
  disabledDays?: (string | number | Date)[];
  min?: string | number | Date;
  max?: string | number | Date;
  mask?: Masks;
  selectableView?: SelectableView;
}

type SelectableView = 'days' | 'months' | 'years';

interface UpdatePickmeup {
  min?: string | number | Date;
  max?: string | number | Date;
  disabledDays?: (string | number | Date)[];
}

export interface CompoundedDatepicker extends React.FC<Props> {
  parse: (date: string, format?: string) => Date;
  format: (date: Date, format?: string) => string;
}

function preventError(cb: Function) {
  function noop() {
    /* do nothing */
  }

  const previousConsole = console;
  const previousOnError = window.onerror;

  console.error = noop;
  window.onerror = noop;

  try {
    cb();
  } finally {
    console.error = previousConsole.error;
    window.onerror = previousOnError;
  }
}

export const FORMAT: Record<SelectableView, string> = {
  days: 'dd/MM/yyyy',
  months: 'MM/yyyy',
  years: 'yyyy',
};

const ISO_FORMAT = 'yyyy-MM-dd';

const LOCALE = 'pt-br';

const DICTIONARY = {
  days: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  daysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  daysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  monthsShort: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ],
};

export const SELECTORS = {
  VIEW: {
    DAYS: 'pmu-view-days',
    MONTHS: 'pmu-view-months',
    YEARS: 'pmu-view-years',
  },
  DAYS: 'pmu-days',
  NEXT: 'pmu-next',
  TODAY: 'pmu-today',
  YEARS: 'pmu-years',
  RESET: 'pmu-reset',
  HIDDEN: 'pmu-hidden',
  MONTHS: 'pmu-months',
  BUTTON: 'pmu-button',
  ACTIONS: 'pmu-actions',
  SELECTED: 'pmu-selected',
};

const MASK: Record<SelectableView, Masks> = {
  days: 'DATE',
  months: 'MONTH_DATE',
  years: 'NUMBER',
};

const Datepicker: CompoundedDatepicker = ({
  mode,
  value,
  onInit,
  onChange,
  className,
  mask,
  selectableView = 'days',
  ...props
}) => {
  const [internalValue, setInternalValue] = React.useState('');
  const [internalError, setInternalError] = React.useState('');

  const inputRef = React.createRef<HTMLInputElement>();
  const instanceRef = React.useRef<PickmeupInstance>();

  function normalizeDate(date: string | number | Date): Date {
    switch (typeof date) {
      case 'object':
        return date;
      case 'number':
        return new Date(date);
      default:
        return parseDate(date, FORMAT[selectableView], 0);
    }
  }

  function handleFill() {
    /* istanbul ignore next */
    if (!inputRef.current) return;

    const datepicker = inputRef.current['__pickmeup'].element;

    if (props['data-testid']) {
      datepicker.dataset.testid = `${props['data-testid']}-datepicker`;
    }

    const reset = datepicker.querySelector(`.${SELECTORS.RESET}`);

    reset.addEventListener('click', (e: Event) => {
      e.preventDefault();
      e.stopPropagation();

      datepicker.classList.remove(
        SELECTORS.VIEW.DAYS,
        SELECTORS.VIEW.YEARS,
        SELECTORS.VIEW.MONTHS
      );
      datepicker.classList.add(SELECTORS.VIEW.DAYS);

      instanceRef.current && instanceRef.current.set_date(new Date());
    });

    inputRef.current.removeEventListener('pickmeup-fill', handleFill);
  }

  function clearErrorInternal() {
    setInternalError('')
  }

  function issetErrorInternal(error: string) {
    setInternalError(error)
  }

  function handleFocus() {
    instanceRef.current && instanceRef.current.show();
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement> | any) {
    const value = (e.target.value || '') as string;


    const date = value
      ? Datepicker.parse(value, value.indexOf('-') !== -1 ? ISO_FORMAT : FORMAT[selectableView])
      : undefined;


    const validDate =
      date && date.getFullYear().toString().length === 4 ? date : '';


    if (!isValidDate(validDate)) issetErrorInternal('Data inválida')

    if (validDate && isValidDate(validDate)) {
      if (!isMobile() && instanceRef.current) {
        instanceRef.current.set_date(validDate);
      }
      clearErrorInternal()
    }

    setInternalValue(value);

    onChange &&
      onChange(isMobile() ? formatDate(parseISODate(value), FORMAT[selectableView]) : value);
  }

  function init() {
    /* istanbul ignore next */
    if (!inputRef.current) return;

    const parsedValue =
      value !== undefined
        ? parseDate(value as string, FORMAT[selectableView], new Date())
        : undefined;

    const pickmeupOptions: PickmeupInstanceOptions = {
      mode,
      prev: PREVICON,
      next: NEXTICON,
      format: selectableView === 'days' ? 'd/m/Y' : selectableView === 'months' ? 'm/Y' : 'Y',
      locale: LOCALE,
      select_day: selectableView === 'days',
      select_month: selectableView === 'days' || selectableView === 'months',
      current: isValidDate(parsedValue) ? parsedValue : new Date(),
      locales: {
        [LOCALE]: DICTIONARY,
      },
      separator: ',',
      first_day: 0,
      title_format(date: Date, { months }: { months: string[] }) {
        return `${months[date.getMonth()]} de ${date.getFullYear()}`;
      },
      default_date: false,
      hide_on_select: mode !== 'multiple',
      instance_template(options: any) {
        const template = new DOMParser().parseFromString(
          pickmeup.defaults.instance_template(options),
          'text/html'
        ).body.childNodes[0] as Element;

        const actions = document.createElement('nav');
        actions.classList.add(SELECTORS.ACTIONS);

        const button = document.createElement('button');
        button.classList.add(SELECTORS.BUTTON, SELECTORS.RESET);
        button.innerHTML = 'Ir para mês atual';

        if (props['data-testid']) {
          button.dataset.testid = `${props['data-testid']}-reset`;
        }

        if (selectableView === 'days') {
          actions.appendChild(button);
          template.appendChild(actions);
        }

        /* istanbul ignore next */
        if (inputRef.current) {
          inputRef.current.addEventListener('pickmeup-fill', handleFill);
          inputRef.current.addEventListener('pickmeup-change', handleChange);
        }

        return template.outerHTML;
      },
      instance_content_template(elements: Element[], className?: string) {
        let enhancedElements = elements;

        if (className === SELECTORS.DAYS) {
          enhancedElements = elements.map(element => {
            const date = new Date(
              element['__pickmeup_year'],
              element['__pickmeup_month'],
              element['__pickmeup_day']
            );
            element.setAttribute('title', `${formatDate(date, FORMAT[selectableView])}`);

            return element;
          });
        } else if (className === SELECTORS.MONTHS) {
          enhancedElements = elements.map(element => {
            element.setAttribute(
              'title',
              DICTIONARY.months[element['__pickmeup_month']]
            );

            return element;
          });
        }

        return pickmeup.defaults.instance_content_template(
          enhancedElements,
          className
        );
      },
    };

    if (props.min) {
      pickmeupOptions.min = normalizeDate(props.min);
    }

    if (props.max) {
      pickmeupOptions.max = normalizeDate(props.max);
    }

    pickmeupOptions.render = (date: Date) => {
      const disabledDays = (props.disabledDays || []).map(disabledDay =>
        formatDate(normalizeDate(disabledDay), ISO_FORMAT)
      );

      const disabled =
        (pickmeupOptions.min && date < pickmeupOptions.min) ||
        (pickmeupOptions.max && date > pickmeupOptions.max) ||
        disabledDays.indexOf(formatDate(date, ISO_FORMAT)) !== -1;

      return { disabled };
    };

    instanceRef.current = pickmeup(inputRef.current, pickmeupOptions);

    const datepicker = inputRef.current['__pickmeup'].element;
    const isHidden = datepicker.classList.contains(SELECTORS.HIDDEN);

    instanceRef.current.update();

    if (isHidden) {
      instanceRef.current.hide();
    }

    requestAnimationFrame(() => {
      onInit && onInit();
    });
  }

  const mobileValue = React.useMemo(() => {
    if (value === undefined) return internalValue;

    const parsedValue = Datepicker.parse(value as string);

    return parsedValue && isValidDate(parsedValue)
      ? Datepicker.format(parsedValue, ISO_FORMAT)
      : '';
  }, [value, internalValue]);

  const updatePickmeup = React.useCallback(
    ({ min, max, disabledDays }: UpdatePickmeup) => {
      if (!instanceRef || !instanceRef.current) return;

      const pickmeupOptions: Partial<PickmeupInstanceOptions> = {};

      if (min) {
        pickmeupOptions.min = normalizeDate(min);
        inputRef!.current!['__pickmeup'].options.min = pickmeupOptions.min;
      }

      if (max) {
        pickmeupOptions.max = normalizeDate(max);
        inputRef!.current!['__pickmeup'].options.max = pickmeupOptions.max;
      }

      inputRef!.current!['__pickmeup'].options.render = (date: Date) => {
        const _disabledDays = (disabledDays || []).map(disabledDay =>
          formatDate(normalizeDate(disabledDay), ISO_FORMAT)
        );

        const disabled =
          (pickmeupOptions.min && date < pickmeupOptions.min) ||
          (pickmeupOptions.max && date > pickmeupOptions.max) ||
          _disabledDays.indexOf(formatDate(date, ISO_FORMAT)) !== -1;

        return { disabled };
      };

      instanceRef.current.update();
      instanceRef.current.hide();
    },
    [instanceRef, inputRef]
  );

  React.useEffect(() => {
    if (instanceRef && instanceRef.current) {
      updatePickmeup({ ...props });
      return;
    }

    if (isMobile()) {
      onInit && onInit();
      return;
    }

    init();
  }, [props.min, props.max, props.disabledDays]);

  React.useEffect(() => {
    return () => {
      /* we need to do that because the lib did not handle some errors.
       ** if you need to show errors, just remove preventError wrapper */
      preventError(
        () => !!instanceRef.current && instanceRef.current.destroy()
      );
    };
  }, []);

  return (
    <div className={cn('sicredi-datepicker', className)}>
      {isMobile() ? (
        <Input
          icon={<Icon name="calendar" />}
          type="date"
          value={mobileValue}
          onChange={handleChange}
          {...props}
          min={
            typeof props.min === 'object'
              ? Datepicker.format(props.min, ISO_FORMAT)
              : props.min
          }
          max={
            typeof props.max === 'object'
              ? Datepicker.format(props.max, ISO_FORMAT)
              : props.max
          }
        />
      ) : (
        <MaskedInput
          ref={inputRef}
          icon={<Icon name="calendar" />}
          type="tel"
          mask={mask || MASK[selectableView]}
          value={value !== undefined ? value : internalValue}
          onFocus={handleFocus}
          onChange={handleChange}
          errorMessage={props.errorMessage || internalError}
          {...props}
          min={
            typeof props.min === 'object'
              ? formatDate(props.min, ISO_FORMAT)
              : props.min
          }
          max={
            typeof props.max === 'object'
              ? formatDate(props.max, ISO_FORMAT)
              : props.max
          }
        />
      )}
    </div>
  );
};

Datepicker.parse = function (date: string, format: string = FORMAT.days): Date {
  const parsedDate = parseISODate(date);

  if (isValidDate(parsedDate)) return parsedDate;

  return parseDate(date, format, new Date());
};

Datepicker.format = function (
  date: Date | number,
  format: string = FORMAT.days
): string {
  return formatDate(date, format);
};

Datepicker.defaultProps = {
  mode: 'single',
};

export default Datepicker;
