import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import cn from 'clsx';

import { createPopper, Instance, Placement } from '@popperjs/core';
import styles from '@sicredi/styles/_tooltip.scss';


export interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  children?: React.ReactNode;
  tip: React.ReactNode;
  placement: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
  allowInDisabledElement?: boolean;
}

const Tooltip: React.FC<Props> = ({
  tip,
  children,
  placement,
  className,
  allowInDisabledElement,
  ...props
}) => {
  const [hovered, setHover] = React.useState(false);
  const [popperInstance, setInstance] = React.useState({} as Instance);
  const [innerPlacement, setPlacement] = React.useState<Placement>(placement);

  const tooltipRef = React.useRef<HTMLSpanElement>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!wrapperRef.current || !tooltipRef.current) return;

    const popper = createPopper(wrapperRef.current, tooltipRef.current, {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    });

    setInstance(popper);

    return () => {
      popper.destroy();
    };
  }, []);

  async function handleHover(isVisible: boolean) {
    if (hovered === isVisible) return;

    setHover(isVisible);
    if (popperInstance) {
      const state = await popperInstance.update();
      setPlacement(state.placement ? state.placement : placement);
    }
  }
  const id = useMemo(() => uuidv4(), [tip]);

  return (
    <React.Fragment>
      <span
        ref={wrapperRef}
        className={cn(styles['sicredi-tooltip'], className)}
        aria-describedby={id}
        tabIndex={0}
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
        onKeyDown={() => handleHover(false)}
        onKeyUp={() => handleHover(true)}
        onMouseOver={allowInDisabledElement ? () => handleHover(true) : undefined}
        {...props}
      >
        {children}
      </span>
      <span
        id={id}
        ref={tooltipRef}
        role="tooltip"
        className={cn(styles['sicredi-tooltip-tip'], {
          [styles['-top']]: innerPlacement === 'top',
          [styles['-bottom']]: innerPlacement === 'bottom',
          [styles['-left']]: innerPlacement === 'left',
          [styles['-right']]: innerPlacement === 'right',
        })}
        aria-hidden={hovered ? 'false' : 'true'}
      >
        {tip}
        <span data-popper-arrow="" className={styles['arrow']}></span>
      </span>
    </React.Fragment>
  );
};

Tooltip.defaultProps = {
  placement: 'top',
};

export default Tooltip;
