import styles from '@sicredi/styles/_toast.scss';
import cn from 'clsx';
import * as React from 'react';
import { Button } from '../button';
import { Icon } from '../icon';

export type ToastActionButtonConfig = { onClick?: () => void, label: string };

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  show?: boolean;
  onClose: () => void;
  actionButton?: ToastActionButtonConfig;
  className?: string;
  appearance?: 'success' | 'warning' | 'error';
}

const Toast: React.FC<Props> = ({
  show,
  onClose,
  children,
  className,
  appearance,
  actionButton,
  ...props
}) => {

  return (
    <div
      role="alert"
      tabIndex={0}
      className={cn(styles['sicredi-toast'], className, {
        [styles['-show']]: show,
        [styles['-error']]: appearance === 'error',
        [styles['-success']]: appearance === 'success',
        [styles['-warning']]: appearance === 'warning',
      })}
      aria-live="polite"
      data-toast=""
      {...props}
    >
      {children}
      <div className={styles['sicredi-toast-buttons-wrapper']}>
        {!!actionButton && (
          <Button
            onClick={actionButton.onClick}
            tabIndex={0}
            aria-label="Ação"
            className={cn(styles['action'])}
          >
            {actionButton.label}
          </Button>
        )}
        {onClose && (
          <button
            type="button"
            onClick={onClose}
            tabIndex={0}
            className={styles['close']}
            aria-label="Fechar"
            data-button=""
          >
            <Icon name="close" className={styles['icon']} />
          </button>
        )}
      </div>
    </div>
  );
};

export default Toast;
