import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { v4 as publicIpv4 } from 'public-ip';
import { APPLICATION } from '../../constants';
import { Category, Domain } from '../Interfaces/Domain.Interface';
import { CookiesConsentPayload } from '../Interfaces/CookiesConsent.Interface';
import {
  Consent_Action,
  DesktopBrowsers_AgentData,
} from '../Enums/Browsers.Enum';
import { UserConsentCreateConfig } from '../Interfaces/UserConsent.Interface';
import {
  createCookies,
  saveDataInTheLocalStorage,
} from '../BrowserDataManipulation/CreateDataInTheBrowser/createDataInTheBrowser';
import { createConsentService } from '../../services/createConsentService';
interface CategoriesContextType {
  domain: Domain | null;
  setDomain: React.Dispatch<React.SetStateAction<Domain | null>>;
  categories: Array<Category>;
  setCategories: React.Dispatch<React.SetStateAction<Category[]>>;
  selectAllCategories: boolean;
  setSelectAllCategories: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialValue: CategoriesContextType = {
  domain: null,
  setDomain: () => {},
  categories: [],
  setCategories: () => {},
  selectAllCategories: false,
  setSelectAllCategories: () => {},
};

export const CategoryContext =
  createContext<CategoriesContextType>(initialValue);

interface CategoryProviderProps {
  children: ReactNode;
}

export const CategoryProvider = ({ children }: CategoryProviderProps) => {
  const [domain, setDomain] = useState<Domain | null>(null);
  const [categories, setCategories] = useState<Array<Category>>([]);
  const [selectAllCategories, setSelectAllCategories] =
    useState<boolean>(false);

  return (
    <CategoryContext.Provider
      value={{
        domain,
        setDomain,
        categories,
        setCategories,
        selectAllCategories,
        setSelectAllCategories,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

export const useCategory = () => {
  const {
    domain,
    setDomain,
    categories,
    setCategories,
    selectAllCategories,
    setSelectAllCategories,
  } = useContext(CategoryContext);

  const domain_ = APPLICATION.DOMINIO;

  const strictNecessary = 'Estritamente Necessários'.trim().toLowerCase();

  const strictNecessaryUUID = categories.find((item) => {
    return item.name.trim().toLowerCase() === strictNecessary;
  })?.uuid;

  const setAllCategories = (able: boolean) => {
    const allCategories = categories.map((category) => {
      if (category.name.trim().toLowerCase() === strictNecessary) {
        return {
          ...category,
          able: true,
        };
      }
      return { ...category, able: able };
    });

    setCategories(allCategories);

    setSelectAllCategories(able);

    saveDataInTheLocalStorage(domain, allCategories);
  };

  const hasCategoriesUnable = (uuid: string, able: boolean) => {
    return categories.map((category) => {
      if (selectAllCategories) {
        if (category.uuid === uuid && !able) {
          setSelectAllCategories(false);
        }
      }
    });
  };

  const hasAllCategoriesAble = (categorySwitchAction: Category[]) => {
    const hasCategoriesUnable = categorySwitchAction
      .filter((category) => {
        return category.name.trim().toLowerCase() !== strictNecessary;
      })
      .every((category) => category.able);

    setSelectAllCategories(hasCategoriesUnable);
  };

  const setIndividualCategory = (uuid: string, able: boolean) => {
    const categorySwitchAction = categories.map((category) => {
      if (category.uuid === uuid) {
        return { ...category, able };
      }
      return category;
    });

    setCategories(categorySwitchAction);

    hasCategoriesUnable(uuid, able);

    hasAllCategoriesAble(categorySwitchAction);

    saveDataInTheLocalStorage(domain, categorySwitchAction);
  };

  const getUserBrowserAgentData = useCallback(async () => {
    const browser = navigator.userAgentData;

    if (!browser) {
      return getUserBrowserAgent();
    } else {
      const desktopBrowserList = Object.values(DesktopBrowsers_AgentData).map(
        String,
      );

      const browsersAgentData = browser.brands.filter(({ brand }) => {
        return desktopBrowserList.includes(brand.toUpperCase());
      });

      const [browserName] = browsersAgentData;

      return {
        agent: navigator.userAgent,
        device: browser.mobile ? 'Mobile' : 'Desktop',
        ipAddress: await publicIpv4(),
        browser: browserName.brand ?? '',
        os: browser.platform ?? '',
      };
    }
  }, [categories]);

  const getUserBrowserAgent = useCallback(async () => {
    const browser = navigator.userAgent;

    const regexBrowserName = new RegExp(/(Edg|Safari|Firefox|Chrome|OPR)+/g);
    const regexOS = new RegExp(/(Windows|Linux|MAC)+/g);
    const regexDevice = new RegExp(/(Mobile)+/g);

    const browsersData = browser
      .replaceAll('(', '')
      .replaceAll(')', '')
      .split(' ');

    const [browserName] = browsersData.at(-1)?.match(regexBrowserName) ?? [];

    const [browserOS] = browsersData.filter((item) => {
      return item.match(regexOS);
    });

    const [browserDevice] = browsersData.filter((item) => {
      return item.match(regexDevice);
    });

    return {
      agent: browser,
      device: browserDevice ?? 'Desktop',
      ipAddress: await publicIpv4(),
      browser: (browserName === 'Edg' ? 'Microsoft Edge' : browserName) ?? '',
      os: browserOS,
    };
  }, [categories]);

  const userConsentBuild = async (
    action = Consent_Action.SAVE,
    allowAll = false,
  ) => {
    const USERCUUID = uuidv4();
    createCookies(USERCUUID);

    const browserData = await getUserBrowserAgentData();

    const body: CookiesConsentPayload = {
      agente: browserData.agent,
      categoriaCookieDtos: categories.map((category) => {
        if (action === Consent_Action.REJECT) {
          return {
            categoriaDto: {
              uuid: category.uuid,
            },
            habilitado: category.uuid === strictNecessaryUUID ? true : allowAll,
          };
        }

        return {
          categoriaDto: {
            uuid: category.uuid,
          },
          habilitado: allowAll ? true : category.able,
        };
      }),
      dispositivo: browserData.device,
      dominio: domain_,
      enderecoIp: browserData.ipAddress,
      navegador: browserData.browser,
      os: browserData.os,
      usuario: USERCUUID,
    };

    return body;
  };

  const createUserConsent = async (
    action = Consent_Action.SAVE,
    allowAll = false,
  ) => {
    const body = await userConsentBuild(action, allowAll);

    const mutateConfig: UserConsentCreateConfig = {
      body,
      user: body.usuario,
      domain,
    };

    createConsentService(mutateConfig, domain_);
  };

  return {
    domain_,
    domain,
    setDomain,
    categories,
    setCategories,
    selectAllCategories,
    setAllCategories,
    setIndividualCategory,
    createUserConsent,
  };
};
