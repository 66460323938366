import * as React from 'react';
import cn from 'clsx';
import styles from '@sicredi/styles/_pagination.scss';
import { Icon } from '../icon';

export interface Props
  extends Pick<
    React.HTMLAttributes<HTMLUListElement>,
    Exclude<keyof React.HTMLAttributes<HTMLUListElement>, 'onChange'>
  > {
  children?: React.ReactNode;
  numberOfPages: number;
  currentPage?: number;
  onChange?: (page: number) => void;
}

interface SetupPagesConfig {
  currentPage: number;
  numberOfPages: number;
}

interface Page {
  page: number | '...';
  selected: boolean;
  key: number;
}

const PAGINATION_SIZE = 7;
const FIRST_PAGE = 1;
const MINIMUM_INITIAL_ELLIPSE = 4;
const MINIMUM_PAGE_BUTTONS = 3;
const ELLIPSE_PLUS_FIRST_OR_LAST = 2;
const MAXIMUM_NUMBER_OF_PAGES_WITHOUT_ELLIPSIS = 5;
const createPages = ({
  numberOfPages,
  currentPage,
}: SetupPagesConfig): Page[] => {
  if (numberOfPages === 0) return [];
  const shouldHaveInitialEllipsis =
    currentPage - FIRST_PAGE >= MINIMUM_INITIAL_ELLIPSE &&
    numberOfPages > MAXIMUM_NUMBER_OF_PAGES_WITHOUT_ELLIPSIS &&
    numberOfPages > PAGINATION_SIZE;
  const shouldHaveFinalEllipsis =
    currentPage + MINIMUM_PAGE_BUTTONS < numberOfPages &&
    numberOfPages > PAGINATION_SIZE &&
    numberOfPages > MAXIMUM_NUMBER_OF_PAGES_WITHOUT_ELLIPSIS;
  const pagesLength =
    MINIMUM_PAGE_BUTTONS +
    (shouldHaveInitialEllipsis ? 0 : ELLIPSE_PLUS_FIRST_OR_LAST) +
    (shouldHaveFinalEllipsis ? 0 : ELLIPSE_PLUS_FIRST_OR_LAST);
  const pages: Page[] = [];
  let startUpPage = shouldHaveInitialEllipsis
    ? shouldHaveFinalEllipsis
      ? currentPage - FIRST_PAGE
      : numberOfPages - MINIMUM_INITIAL_ELLIPSE
    : FIRST_PAGE;

  if (shouldHaveInitialEllipsis) {
    pages.push(
      { page: FIRST_PAGE, selected: false, key: FIRST_PAGE },
      { page: '...', selected: false, key: 0 }
    );
  }

  for (
    let index = 0;
    index < pagesLength && startUpPage <= numberOfPages;
    index += 1
  ) {
    pages.push({
      selected: startUpPage === currentPage,
      key: startUpPage,
      page: startUpPage,
    });
    startUpPage += 1;
  }

  if (shouldHaveFinalEllipsis) {
    pages.push(
      { page: '...', selected: false, key: numberOfPages + 1 },
      { page: numberOfPages, selected: false, key: numberOfPages }
    );
  }

  return pages;
};

const Pagination: React.FC<Props> = ({
  className,
  numberOfPages,
  currentPage,
  onChange,
  ...props
}) => {
  const [internalCurrentPage, setInternalCurrentPage] = React.useState(1);

  const correctCurrentPage =
    currentPage !== undefined ? currentPage : internalCurrentPage;

  function handleChangePage(page: 'previous' | 'next' | '...' | number) {
    return function() {
      const newPage =
        typeof page === 'number'
          ? page
          : page === 'previous'
          ? correctCurrentPage - 1
          : correctCurrentPage + 1;
      setInternalCurrentPage(newPage);
      onChange && onChange(newPage);
    };
  }

  const pages = createPages({
    numberOfPages,
    currentPage: correctCurrentPage,
  });

  return (
    <nav
      role="navigation"
      className={cn(styles['sicredi-pagination'], className)}
      aria-label="Paginação"
      {...props}
    >
      <ol>
        <li>
          <button
            type="button"
            className={cn(styles['item'], {
              [styles['arrow']]: correctCurrentPage === 1,
            })}
            disabled={correctCurrentPage === 1}
            onClick={handleChangePage('previous')}
            data-testid="previous-page-button"
            aria-label={`Voltar ${
              correctCurrentPage === 1
                ? 'página'
                : `para página ${correctCurrentPage - 1}`
            }`}
          >
            <Icon name="chevron-left" />
          </button>
        </li>
        {pages.map(({ page, selected, key }) => (
          <li key={`${key}`}>
            <button
              type="button"
              className={cn(styles['item'], {
                [styles['-selected']]: selected,
                [styles['ellipsis']]: page === '...',
              })}
              data-testid={`pagination-page-${key}`}
              onClick={handleChangePage(page)}
              aria-hidden={typeof page !== 'number' ? 'true' : 'false'}
              aria-label={`${
                typeof page !== 'number' ? '' : `Ir para a página ${page}`
              }`}
              aria-current={page === correctCurrentPage}
              disabled={typeof page !== 'number' || selected}
            >
              {page}
            </button>
          </li>
        ))}
        <li>
          <button
            type="button"
            className={cn(styles['item'], {
              [styles['arrow']]: correctCurrentPage === numberOfPages,
            })}
            disabled={correctCurrentPage === numberOfPages}
            onClick={handleChangePage('next')}
            data-testid="next-page-button"
            aria-label={`Avançar ${
              correctCurrentPage === numberOfPages
                ? 'página'
                : `para página ${correctCurrentPage + 1}`
            }`}
          >
            <Icon name="chevron-right" />
          </button>
        </li>
      </ol>
    </nav>
  );
};

export default Pagination;
