import * as React from 'react';
import cn from 'clsx';
import styles from '@sicredi/styles/_checkbox.scss';

import { hash } from '../utils';
import FieldSubscript from '../shared/field-subscript/FieldSubscript';
import { Spacing } from '../spacing';
import CheckboxIcon from './Icon';

export type Value = string | number;

export interface Data {
  label: React.ReactNode;
  value: string | number;
  description?: React.ReactNode;
  info?: React.ReactNode;
}

export interface Schema {
  children?: React.ReactNode;
  name: string;
  data: Data[];
  value?: Value[];
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (value: Value[]) => void;
  className?: string;
  hintMessage?: React.ReactNode;
  errorMessage?: React.ReactNode;
  direction?: 'horizontal' | 'vertical';
  appearance?: 'card';
}

export interface AriaLabelProps extends Schema {
  'aria-label': string;
}

export interface AriaLabelledbybyProps extends Schema {
  'aria-labelledby': string;
}

export type Props = AriaLabelProps | AriaLabelledbybyProps;

const Group: React.FC<Props> = ({
  name,
  data,
  value,
  required,
  disabled,
  readOnly,
  onChange,
  className,
  hintMessage,
  errorMessage,
  direction,
  appearance,
  ...props
}) => {
  const [internalValue, setInternalValue] = React.useState<Value[]>([]);

  const values = (value !== undefined ? value : internalValue).map(String);

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement>
  ): boolean | void {
    if (disabled || readOnly) {
      e.preventDefault();
      e.stopPropagation();

      return false;
    }

    const nextValue = values.includes(e.target.value)
      ? values.filter(v => v !== e.target.value)
      : [...values, e.target.value];

    setInternalValue(nextValue);

    onChange && onChange(nextValue);
  }

  return (
    <div className={cn(styles['sicredi-checkbox-wrapper'], className)}>
      <div
        role="group"
        data-group=""
        aria-label={props['aria-label'] || undefined}
        aria-labelledby={props['aria-labelledby'] || undefined}
        className={cn({
          [styles['-vertical']]: direction === 'vertical',
          [styles['-horizontal']]: direction === 'horizontal',
        })}
      >
        {data.map(checkbox => (
          <div
            key={hash(name + checkbox.value)}
            className={cn(styles['sicredi-checkbox'], {
              [styles['-invalid']]: !!errorMessage,
              [styles['-disabled']]: disabled,
              [styles['-readonly']]: readOnly,
              [styles['-card']]: appearance === 'card',
            })}
            data-checkbox=""
          >
            <input
              id={`${hash(name + checkbox.value)}-checkbox`}
              name={name}
              type="checkbox"
              role="checkbox"
              value={checkbox.value}
              checked={values.includes(String(checkbox.value))}
              onChange={handleChange}
              required={required}
              tabIndex={disabled || readOnly ? -1 : 0}
              disabled={disabled}
              readOnly={readOnly}
              className={styles['input']}
              data-input=""
              data-testid={
                props['data-testid'] ? props['data-testid'] : undefined
              }
              aria-checked={values.includes(String(checkbox.value))}
              aria-invalid={errorMessage ? 'true' : 'false'}
              aria-readonly={readOnly ? 'true' : 'false'}
              aria-disabled={disabled ? 'true' : 'false'}
              aria-required={required ? 'true' : 'false'}
              aria-labelledby={`${hash(name + checkbox.value)}-checkbox-label`}
              aria-errormessage={`${name}-checkbox-error`}
            />
            <label
              id={`${hash(name + checkbox.value)}-checkbox-label`}
              htmlFor={`${hash(name + checkbox.value)}-checkbox`}
              className={styles['label']}
              data-label=""
            >
              {appearance === 'card' ? (
                <React.Fragment>
                  <div className={styles['header']}>
                    <div className={styles['title']}>
                      {checkbox.info && (
                        <div className={styles['info']}>{checkbox.info}</div>
                      )}
                      <div className={styles['heading']}>{checkbox.label}</div>
                    </div>
                    <CheckboxIcon
                      checked={values.includes(String(checkbox.value))}
                    />
                  </div>
                  {checkbox.description && (
                    <div className={styles['description']}>
                      {checkbox.description}
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <CheckboxIcon
                    checked={values.includes(String(checkbox.value))}
                  />
                  {checkbox.label && (
                    <div className={styles['label-text']}>{checkbox.label}</div>
                  )}
                </React.Fragment>
              )}
            </label>
          </div>
        ))}
      </div>
      <Spacing appearance="xx-small" />
      <FieldSubscript>
        <FieldSubscript.Group>
          <FieldSubscript.Message
            id={`${name}-input-error`}
            show={!!errorMessage}
            invalid={true}
            aria-live="polite"
            data-testid={
              props['data-testid'] ? `${props['data-testid']}-error` : undefined
            }
            data-message=""
          >
            {errorMessage}
          </FieldSubscript.Message>
          <FieldSubscript.Message
            id={`${name}-input-hint`}
            show={!!hintMessage && !errorMessage}
            data-testid={
              props['data-testid'] ? `${props['data-testid']}-hint` : undefined
            }
            data-message=""
          >
            {hintMessage}
          </FieldSubscript.Message>
        </FieldSubscript.Group>
      </FieldSubscript>
    </div>
  );
};

Group.defaultProps = {
  direction: 'vertical',
};

export default Group;
