import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { UserConsentPT } from '../../Interfaces/UserConsent.Interface';
import { CookieDtosPT } from '#/common/Interfaces/DomainPayload.Interface';

const removeRandomCookies = (cookie: CookieDtosPT) => {
  const regexToGetRandomCookies = /^(.*)\*/;
  const cookieMatch = cookie.nome.match(regexToGetRandomCookies);
  const prefix = cookieMatch ? cookieMatch[1].trim() : '';

  if (prefix) {
    const allCookies = Cookies.get();

    for (const cookieName in allCookies) {
      if (cookieName.startsWith(prefix)) {
        Cookies.remove(cookieName, {
          domain: cookie.domain,
          path: cookie.path,
        });
      }
    }
  }
};

export const useRemoveCookiesDisabled = () => {
  function getCookiesToRemove() {
    const consent = JSON.parse(
      localStorage.getItem('CookiesDomains') as any,
    ) as UserConsentPT;

    const categoriesDisabled = consent?.categoriaDtos?.filter((category) => {
      return category.habilitado === false;
    });

    categoriesDisabled?.forEach((category) => {
      category.cookieDtos.forEach((cookie) => {
        Cookies.remove(cookie.nome, {
          domain: cookie.domain,
          path: cookie.path,
        });

        removeRandomCookies(cookie);
      });
    });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (Cookies.get('USERCUUID')) {
        setTimeout(getCookiesToRemove, 3000);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
};
