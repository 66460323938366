import React, { Fragment } from 'react';
import { Optional } from 'utility-types';
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryPropsWithFallback,
} from 'react-error-boundary';

import { Meta, Modal } from '..';

export type ErrorBoundaryProps = {
  title?: string;
  onClose?: () => void;
  children?: React.ReactNode;
} & Optional<ErrorBoundaryPropsWithFallback, 'fallback'>;

export type FallbackProps = { children?: React.ReactNode } & Pick<
  ErrorBoundaryProps,
  'title' | 'onClose'
>;

export const Fallback = ({
  title = 'Erro inesperado!',
  onClose,
  children = 'Por favor, tente novamente mais tarde.',
}: FallbackProps) => {
  const handleOnClose = () => {
    onClose?.();
  };

  return (
    <Fragment>
      <Meta title={title} />

      <Modal
        show={true}
        title={title}
        onClose={handleOnClose}
        ariaHideApp={process.env.NODE_ENV !== 'test'}
        primaryButton={{
          onClick: handleOnClose,
          children: 'Tentar novamente',
          'data-testid': 'error-boundary-fallback-dismiss',
        }}
      >
        {children}
      </Modal>
    </Fragment>
  );
};

const ErrorBoundary = ({
  title,
  onClose,
  children,
  ...props
}: ErrorBoundaryProps) => {
  return (
    <ReactErrorBoundary
      fallback={<Fallback onClose={onClose} title={title} />}
      {...props}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
