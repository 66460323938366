import React from 'react';
import styles from '@sicredi/styles/_file.scss';
import { Button } from '../button';
import { Props as SicrediButtonProps } from '../button/Button';
import { Icon } from '../icon';
import cn from 'clsx';
export interface File {
  id?: string;
  name: string;
  type?: string;
}

export interface Props
  extends Pick<
    SicrediButtonProps,
    Exclude<keyof SicrediButtonProps, 'onClick'>
  > {
  onRemove?: (index: number) => void;
  onClick?: (file: File) => void;
  file: File;
  isError?: boolean;
}

const FILENAME_SLICE_INDEX: number = -9;

const Item: React.FC<Props> = ({
  onRemove,
  onClick,
  index,
  file,
  className,
  isError,
  ...props
}) => {
  return (
    <li data-item="" className={styles['item']}>
      <section className={styles['details']}>
        <Button
          className={styles['name']}
          appearance="link"
          onClick={() => onClick && onClick(file)}
          {...props}
          data-testid={
            props['data-testid'] ? `${props['data-testid']}-item-name` : undefined
          }
        >
          {isError && <Icon name="alert" appearance='danger' />}

          <span className={cn(styles['left'], {
            [styles['-error']]: isError,
          })}>
            {file.name.slice(0, FILENAME_SLICE_INDEX)}
          </span>

          <span className={cn(styles['right'], {
            [styles['-error']]: isError,
          })}>
            {file.name.slice(FILENAME_SLICE_INDEX)}
          </span>

        </Button>
        {
          onRemove && (
            <button
              type="button"
              onClick={() => onRemove(index)}
              tabIndex={0}
              className={styles['destroy']}
              aria-label={`Remover ${file.name}`}
              data-button=""
              data-testid={
                props['data-testid']
                  ? `${props['data-testid']}-item-destroy`
                  : undefined
              }
            >
              <Icon className={styles['icon']} name="trash" appearance='primary' />
            </button>
          )
        }
      </section>
      {isError && <span data-testid={
        props['data-testid']
          ? `${props['data-testid']}-item-footer-error`
          : undefined
      } className={styles['text-footer']}>Falha ao adicionar arquivo</span>}
    </li >
  );
};

export default Item;
