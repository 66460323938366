import styles from '@sicredi/styles/_box.scss';
import cn from 'clsx';
import * as React from 'react';
import Button, { Props as ButtonProps } from '../button/Button';
import { Icon } from '../icon';
import { Spacing } from '../spacing';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  title?: string;
  icon?: React.ReactNode;
  noContentMessage?: string;
  caption?: string;
  appearence?: 'transparent' | 'secondary';
  footerButton?: Pick<ButtonProps, 'children' | 'onClick'>;
}

const Box: React.FC<Props> = ({
  children,
  title,
  noContentMessage,
  caption,
  className,
  icon,
  appearence = 'transparent',
  footerButton,
  ...props
}) => {
  const hasContent = !!title || !!children;

  return (
    <div className={cn(styles['sicredi-box-wrapper'])}>
      <div
        role="box"
        className={cn(
          styles['sicredi-box'],
          { [styles['secondary']]: appearence === 'secondary' },
          { [styles['-center']]: !hasContent },
          className
        )}
        {...props}
      >
        {title && icon && (
          <>
            <span className={cn(styles['icon'])}>{icon}</span>
            <Spacing appearance="small"></Spacing>
          </>
        )}

        {title && caption && (
          <span className={cn(styles['caption'])}>{caption}</span>
        )}

        {title && <h2 className={cn(styles['title'])}>{title}</h2>}
        {!hasContent && !!noContentMessage && (
          <p className={cn(styles['no-content-message'])}>{noContentMessage}</p>
        )}

        <div className={cn(styles['sicredi-box-content'])}>{children}</div>
      </div>
      {!!footerButton && (
        <Button
          block
          className={cn(styles['sicredi-box-footer-button'])}
          onClick={footerButton.onClick}
        >
          {footerButton.children}
          <Icon
            className={cn(styles['icon'])}
            color="primary"
            name="edit"
            size={16}
          />
        </Button>
      )}
    </div>
  );
};

export default Box;
