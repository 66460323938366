import * as React from 'react';

import { hash } from '../utils';
import { Props as TooltipProps } from "../tooltip/Tooltip"

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  label: React.ReactNode;
  selected?: boolean;
  disabled?: boolean;
  disabledTip?: string;
  placementTip?: TooltipProps["placement"];
  'data-testid'?: string;
}

const Item: React.FC<Props> = ({
  label,
  children,
  selected,
  ...props
}) => (
  <div
    id={`${hash(label)}-tab-content`}
    role="tabpanel"
    aria-hidden={selected ? 'false' : 'true'}
    data-content=""
    aria-labelledby={`${hash(label)}-tab`}
    {...props}
  >
    {children}
  </div>
);

export default Item;
